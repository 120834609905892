import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ChecklistResponse, ControlCategoryResponse, GenericResponse } from '../../types';
import customFetchBase from 'src/store/customFetchBase';
import { NewChecklistInput } from 'src/views/pages/setup/controls/checklist';

export const checklistApi = createApi({
  reducerPath: 'checklistApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditCheckListResponseDTO'],
  endpoints: (builder) => ({
    newChecklist: builder.mutation<ChecklistResponse, NewChecklistInput>({
      query(data) {
        return {
          url: '/checkList/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['auditCheckListResponseDTO'],
    }),
    updateChecklist: builder.mutation<ChecklistResponse, NewChecklistInput>({
      query(data) {
        return {
          url: `/checkList/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['auditCheckListResponseDTO'],
    }),
    getAllChecklists: builder.query<ChecklistResponse[], void>({
      query() {
        return {
          url: '/checkList/getall',
          credentials: 'include',
        };
      },
      providesTags: ['auditCheckListResponseDTO'],
      transformResponse: (data: { auditCheckListResponseDTO: ChecklistResponse[] }) =>
        data.auditCheckListResponseDTO,
    }),
    getCategories: builder.query<ControlCategoryResponse[], void>({
      query() {
        return {
          url: '/checkList/getCategories',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlCategoryResponseDTO: ControlCategoryResponse[] }) =>
        data.auditControlCategoryResponseDTO,
    }),
    getChecklistByID: builder.query<ChecklistResponse[], string>({
      query(id: string) {
        return {
          url: `/checkList/get/${id}`,
          credentials: 'include',
        };
      },
      providesTags: ['auditCheckListResponseDTO'],
      transformResponse: (data: { auditControlCategoryResponseDTO: ChecklistResponse[] }) =>
        data.auditControlCategoryResponseDTO,
    }),
  }),
});

export const {
  useNewChecklistMutation,
  useUpdateChecklistMutation,
  useGetAllChecklistsQuery,
  useGetChecklistByIDQuery,
  useGetCategoriesQuery,
} = checklistApi;
