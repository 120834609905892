import React, { ReactNode } from 'react';
import Status500 from '../Status500';
import { Navigate } from 'react-router';

interface ErrorBoundaryState {
  hasError: boolean;
}
export class ErrorBoundary extends React.Component<
  { children?: ReactNode | undefined },
  ErrorBoundaryState
> {
  constructor(props: Readonly<{ children?: ReactNode | undefined }>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // return <Navigate to={'/status/500'} replace />;
      return <Status500 />;
    }

    return this.props.children;
  }
}
