import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User } from '../types';

interface IUserState {
  user: User;
}

const initialState: IUserState = Object.freeze({
  user: {},
});

export const authSlice = createSlice({
  initialState,
  name: 'authSlice',
  reducers: {
    logout: () => initialState,
    setUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export default authSlice.reducer;

export const { logout, setUser } = authSlice.actions;
