import { Card, Chip, styled } from '@mui/material';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { AppState, useSelector } from 'src/store/Store';

type Props = {
  className?: string;
  children: JSX.Element | JSX.Element[];
  sx?: any;
  gradients?: string[];
};

type StyledCardProps = {
  colors?: string[];
};
const StyledCard = styled(Card)<StyledCardProps>(({ colors }) => ({
  color: 'white',
  ...(colors && {
    background: `linear-gradient(to right bottom, ${colors.join(',')})`,
  }),
}));

const BlankCard = ({ children, className, sx, gradients }: Props) => {
  const customizer = useSelector((state: AppState) => state.customizer);

  const theme = useTheme();
  const borderColor = theme.palette.divider;

  const { p } = sx || { p: 0 };

  return (
    <Card
      sx={{
        p: p,
        border: !customizer.isCardShadow ? `1px solid ${borderColor}` : 'none',
        position: 'relative',
        ...sx,
      }}
      className={className}
      elevation={customizer.isCardShadow ? 9 : 0}
      variant={!customizer.isCardShadow ? 'outlined' : undefined}
      // colors={gradients}
    >
      {children}
    </Card>
  );
};

export default BlankCard;
