import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewRiskFactorAssessmentInput } from 'src/views/pages/setup/risk/Assessment';
import {
  RiskFactorAssessmentResponse,
  GenericResponse,
  RiskFactorResponse,
  RiskFactorCriteriaResponse,
  AuditAreaMasterResponse,
  RiskFactorScoreResponse,
  CustomRiskFactorAssessmentResponse
} from 'src/store/types';
import customFetchBase from 'src/store/customFetchBase';

export const riskFactorAssessmentApi = createApi({
  reducerPath: 'riskFactorAssessmentApi',
  baseQuery: customFetchBase,
  tagTypes: ['riskFactorAssessmentResponseDTO'],
  endpoints: (builder) => ({
    newRiskFactorAssessment: builder.mutation<
      RiskFactorAssessmentResponse,
      {}
    >({
      query(data: any) {
        return {
          url: `/riskAssessment/create/${data.auditAreaId}`,
          method: 'POST',
          body: data
        };
      },
      invalidatesTags: ['riskFactorAssessmentResponseDTO']
    }),
    updateRiskFactorAssessment: builder.mutation<
      RiskFactorAssessmentResponse,
      {}
    >({
      query(data: any) {
        return {
          url: `/riskAssessment/update/${data.id}`,
          method: 'PUT',
          body: data
        };
      },
      invalidatesTags: ['riskFactorAssessmentResponseDTO']
    }),
    getAllRiskFactorAssessment: builder.query<
      RiskFactorAssessmentResponse[],
      void
    >({
      query() {
        return {
          url: '/riskAssessment/getall',
          credentials: 'include'
        };
      },
      providesTags: ['riskFactorAssessmentResponseDTO'],
      transformResponse: (data: {
        riskFactorAssessmentResponseDTO: RiskFactorAssessmentResponse[];
      }) => data.riskFactorAssessmentResponseDTO
    }),
    getAssessmentRiskFactors: builder.query<RiskFactorResponse[], void>({
      query() {
        return {
          url: '/riskAssessment/getFactors',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        riskFactorResponseDTO: RiskFactorResponse[];
      }) => data.riskFactorResponseDTO
    }),
    getAssessmentRiskLevels: builder.query<RiskFactorCriteriaResponse[], void>({
      query() {
        return {
          url: '/riskAssessment/getLevels',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        riskFactorCriteriaResponseDTO: RiskFactorCriteriaResponse[];
      }) => data.riskFactorCriteriaResponseDTO
    }),
    getAssessmentAuditAreas: builder.query<AuditAreaMasterResponse[], void>({
      query() {
        return {
          url: '/riskAssessment/getAuditAreas',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditAreasResponseDTO: AuditAreaMasterResponse[];
      }) => data.auditAreasResponseDTO
    }),
    getRiskFactorScoreTable: builder.query<RiskFactorScoreResponse[], void>({
      query() {
        return {
          url: '/riskFactorScore/getall',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        riskFactorScoreResponseDTO: RiskFactorScoreResponse[];
      }) => data.riskFactorScoreResponseDTO
    }),
    getAssessmentByAuditArea: builder.query<
      CustomRiskFactorAssessmentResponse[],
      number
    >({
      query(id) {
        return {
          url: `/riskAssessment/getAssmntByArea/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        customAssessmentResponseDTO: CustomRiskFactorAssessmentResponse[];
      }) => data.customAssessmentResponseDTO
    })
  })
});

export const {
  useNewRiskFactorAssessmentMutation,
  useUpdateRiskFactorAssessmentMutation,
  useGetAllRiskFactorAssessmentQuery,
  useGetAssessmentRiskFactorsQuery,
  useGetAssessmentRiskLevelsQuery,
  useGetAssessmentAuditAreasQuery,
  useGetRiskFactorScoreTableQuery,
  useLazyGetAssessmentByAuditAreaQuery
} = riskFactorAssessmentApi;
