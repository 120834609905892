import { styled } from '@mui/material/styles';
import { Autocomplete } from '@mui/material';

const CustomAutocomplete = styled((props: any) => <Autocomplete {...props} />)(
  ({ theme }) => ({
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.grey[100]
    }
  })
);

export default CustomAutocomplete;
