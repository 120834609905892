import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { GenericResponse, MenuResponse, PermissionResponse, User, UserRole } from '../types';
import { NewRoleInput } from 'src/views/pages/setup/user/roles';
import { RolePermissionInput } from 'src/views/pages/setup/user/permission';
import customFetchBase from 'src/store/customFetchBase';

export const roleApi = createApi({
  reducerPath: 'roleApi',
  baseQuery: customFetchBase,
  tagTypes: ['roleResponseDTO', 'Permissions'],
  endpoints: (builder) => ({
    newRole: builder.mutation<UserRole, NewRoleInput>({
      query(data) {
        return {
          url: '/role/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['roleResponseDTO'],
    }),
    updateRole: builder.mutation<UserRole, NewRoleInput>({
      query(data) {
        return {
          url: `/role/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['roleResponseDTO'],
    }),
    getAllRoles: builder.query<UserRole[], void>({
      query() {
        return {
          url: '/role/getall',
          credentials: 'include',
        };
      },
      providesTags: ['roleResponseDTO'],
      transformResponse: (data: { roleResponseDTO: UserRole[] }) => data.roleResponseDTO,
    }),
    newPermission: builder.mutation<GenericResponse, RolePermissionInput>({
      query(data) {
        return {
          url: '/mrpr/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    getAllPermissions: builder.query<PermissionResponse[], void>({
      query() {
        return {
          url: '/mrpr/perm/getall',
          credentials: 'include',
        };
      },
      // providesTags: (result) =>
      // result
      //   ? [
      //       ...result.map(({ id }) => ({
      //         type: 'Permissions' as const,
      //         id,
      //       })),
      //       { type: 'Permissions', id: 'LIST' },
      //     ]
      //   : [{ type: 'Permissions', id: 'LIST' }],
      transformResponse: (data: { result: PermissionResponse[] }) => data.result,
    }),
    getMenuLevel: builder.query<MenuResponse[], { rid: string; mpid: string }>({
      query({ rid, mpid }) {
        return {
          url: `/mrpr/getMenus/${rid}/${mpid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { menuOptionResponseDTO: MenuResponse[] }) =>
        data.menuOptionResponseDTO,
    }),
    getUserMenus: builder.query<MenuResponse[], void>({
      query() {
        return {
          url: `/mrpr/getUsermenus`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { menuOptionResponseDTO: MenuResponse[] }) =>
        data.menuOptionResponseDTO,
    }),
  }),
});

export const {
  useGetAllRolesQuery,
  useLazyGetAllRolesQuery,
  useGetAllPermissionsQuery,
  useGetMenuLevelQuery,
  useLazyGetMenuLevelQuery,
  useNewRoleMutation,
  useUpdateRoleMutation,
  useNewPermissionMutation,
  useGetUserMenusQuery,
} = roleApi;
