import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { NewBookObservationInput } from 'src/views/pages/execution/observation/Drafting/Control/Create';
import {
  AuditObservationResponse,
  GenericResponse,
  User,
  ControlCategoryResponse,
  ControlLibraryResponse,
  EngagementResponse,
  WorkProgramResponse,
  AuditAreaMasterResponse,
  ControlObservationResponse,
  ChecklistObservationResponse,
  ObservationSampleResponse,
  ObservationRiskScoreResponse,
  CheckListObservationSampleResponse,
  FileResponse,
} from '../types';
import customFetchBase from 'src/store/customFetchBase';
import { NewChecklistObservationInput } from 'src/views/pages/execution/observation/Drafting/Checklist/Create';
import { NewObservationSamplingInput } from 'src/views/pages/execution/observation/Sampling';

export const bookObservationApi = createApi({
  reducerPath: 'bookObservationApi',
  baseQuery: customFetchBase,
  tagTypes: ['auditObservationResponseDTO'],
  endpoints: (builder) => ({
    newBookObservation: builder.mutation<AuditObservationResponse, NewBookObservationInput>({
      query(data) {
        return {
          url: '/auditObservation/create',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['auditObservationResponseDTO'],
    }),
    updateBookObservation: builder.mutation<AuditObservationResponse, NewBookObservationInput>({
      query(data) {
        return {
          url: `/auditObservation/update/${data.id}`,
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['auditObservationResponseDTO'],
    }),
    newObservationSampling: builder.mutation<
      AuditObservationResponse,
      NewObservationSamplingInput[]
    >({
      query(data) {
        return {
          url: '/osampling/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    newChecklistObservationSampling: builder.mutation<
      ObservationSampleResponse,
      NewObservationSamplingInput[]
    >({
      query(data) {
        return {
          url: '/ocsampling/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    updateChecklistObservationSampling: builder.mutation<
      ObservationSampleResponse,
      NewObservationSamplingInput
    >({
      query(data) {
        return {
          url: `/ocsampling/update/${data.id}`,
          method: 'POST',
          body: data,
        };
      },
    }),
    newChecklistObservation: builder.mutation<
      ChecklistObservationResponse,
      NewChecklistObservationInput
    >({
      query(data) {
        return {
          url: '/checklistObservation/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    swapSubObservation: builder.mutation<AuditObservationResponse, string>({
      query(data) {
        return {
          url: '/auditObservation/swap',
          method: 'POST',
          body: data,
        };
      },
    }),
    // updateBookObservation: builder.mutation<AuditObservationResponse, NewBookObservationInput>({
    //   query(data) {
    //     return {
    //       url: `/update/${data.id}`,
    //       method: 'PUT',
    //       body: data
    //     };
    //   },
    //   invalidatesTags: ['BookObservation'],
    //   transformResponse: (data: { result: AuditObservationResponse }) => data.result
    // }),
    getAllObservations: builder.query<AuditObservationResponse[], string>({
      query(id) {
        return {
          url: `/auditObservation/getObservations/${id}`,
          credentials: 'include',
        };
      },
      providesTags: ['auditObservationResponseDTO'],
      transformResponse: (data: { auditObservationResponseDTO: AuditObservationResponse[] }) =>
        data.auditObservationResponseDTO,
    }),
    getAllChecklistObservations: builder.query<AuditObservationResponse[], string>({
      query(id) {
        return {
          url: `/checklistObservation/getObservations/${id}`,
          credentials: 'include',
        };
      },
      providesTags: ['auditObservationResponseDTO'],
      transformResponse: (data: { checkListObservationResponseDTO: AuditObservationResponse[] }) =>
        data.checkListObservationResponseDTO,
    }),
    getObservationNatures: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/auditObservation/getNature',
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationNatureResponseDTO: GenericResponse[] }) =>
        data.observationNatureResponseDTO,
    }),
    getObservationTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/auditObservation/getType',
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationTypeResponseDTO: GenericResponse[] }) =>
        data.observationTypeResponseDTO,
    }),
    getObservationRisks: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/auditObservation/getRisk',
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationRiskResponseDTO: GenericResponse[] }) =>
        data.observationRiskResponseDTO,
    }),
    getObservationRiskTypes: builder.query<GenericResponse[], void>({
      query() {
        return {
          url: '/auditObservation/getRiskType',
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationRiskTypeResponseDTO: GenericResponse[] }) =>
        data.observationRiskTypeResponseDTO,
    }),
    getDepartment: builder.query<AuditAreaMasterResponse[], string>({
      query(id) {
        return {
          url: `/auditObservation/getDepartment/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditAreaTypeMasterResponseDTO: AuditAreaMasterResponse[] }) =>
        data.auditAreaTypeMasterResponseDTO,
    }),
    getWorkPrograms: builder.query<WorkProgramResponse[], string>({
      query(id) {
        return {
          url: `/auditObservation/getWorkPrograms/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse[] }) =>
        data.workProgramResponseDTO,
    }),
    getObservations: builder.query<AuditObservationResponse[], { id: number; nature: number }>({
      query({ id, nature }) {
        return {
          url: `/auditObservation/getObservations/${id}/${nature}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditObservationResponseDTO: AuditObservationResponse[] }) =>
        data.auditObservationResponseDTO,
    }),
    getCheckListWorkPrograms: builder.query<
      WorkProgramResponse[],
      { engid: string; catid: string; concid: string }
    >({
      query({ engid, catid, concid }) {
        return {
          url: `/checklistObservation/getCheckListWorkPrograms/${engid}/${catid}/${concid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse[] }) =>
        data.workProgramResponseDTO,
    }),
    getCategories: builder.query<GenericResponse[], string>({
      query(id: string) {
        return {
          url: `/checklistObservation/getCategories/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditControlCategoryResponseDTO: GenericResponse[] }) =>
        data.auditControlCategoryResponseDTO,
    }),
    getControlObservatinos: builder.query<
      ControlObservationResponse[],
      { engid: bigint; cid: bigint }
    >({
      query({ engid, cid }) {
        return {
          url: `/checklistObservation/getLiabrary/${engid}/${cid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        auditControlLiabraryResponseDTO: ControlObservationResponse[];
      }) => data.auditControlLiabraryResponseDTO,
    }),
    getRiskScoreTable: builder.query<ObservationRiskScoreResponse[], void>({
      query() {
        return {
          url: `/ocsampling/getRiskScore`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        observationRiskScoreResponseDTO: ObservationRiskScoreResponse[];
      }) => data.observationRiskScoreResponseDTO,
    }),
    getChecklistObservationSampling: builder.query<CheckListObservationSampleResponse, string>({
      query(id) {
        return {
          url: `/ocsampling/getSampling/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        checkListObservationSampleResponseDTO: CheckListObservationSampleResponse;
      }) => data.checkListObservationSampleResponseDTO,
    }),
    getObservationSampling: builder.query<ObservationSampleResponse, string>({
      query(id) {
        return {
          url: `/osampling/getSampling/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { observationSampleResponseDTO: ObservationSampleResponse }) =>
        data.observationSampleResponseDTO,
    }),
    getControlObservationAttachments: builder.query<FileResponse[], { engid: any; obvid: any }>({
      query({ engid, obvid }) {
        return {
          url: `/auditObservation/getAttachments/${engid}/${obvid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { fileAttachmentResponseDTO: FileResponse[] }) =>
        data.fileAttachmentResponseDTO,
    }),
    getOutstandingWorkprogramCount: builder.query<string, { engid: any; engtypeid: any }>({
      query({ engid, engtypeid }) {
        return {
          url: `/auditObservation/getUnBookedWPCount/${engid}/${engtypeid}`,
          credentials: 'include',
        };
      },
      // transformResponse: (data: {
      //   auditSampleBaseResponseDTO: GenericResponse[];
      // }) => data.auditSampleBaseResponseDTO
    }),
    getOutstandingWorkprograms: builder.query<
      WorkProgramResponse[],
      { engid: any; engtypeid: any }
    >({
      query({ engid, engtypeid }) {
        return {
          url: `/auditObservation/getUnBookedWP/${engid}/${engtypeid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { workProgramResponseDTO: WorkProgramResponse[] }) =>
        data.workProgramResponseDTO,
    }),
  }),
});

export const {
  useNewBookObservationMutation,
  useUpdateBookObservationMutation,
  useNewObservationSamplingMutation,
  useNewChecklistObservationMutation,
  useNewChecklistObservationSamplingMutation,
  useUpdateChecklistObservationSamplingMutation,
  useSwapSubObservationMutation,
  useLazyGetAllObservationsQuery,
  useLazyGetAllChecklistObservationsQuery,
  useGetObservationNaturesQuery,
  useGetObservationTypesQuery,
  useGetObservationRisksQuery,
  useGetObservationRiskTypesQuery,
  useLazyGetWorkProgramsQuery,
  useLazyGetDepartmentQuery,
  useLazyGetObservationsQuery,
  useLazyGetCheckListWorkProgramsQuery,
  useLazyGetCategoriesQuery,
  useLazyGetControlObservatinosQuery,
  useGetRiskScoreTableQuery,
  useLazyGetChecklistObservationSamplingQuery,
  useLazyGetObservationSamplingQuery,
  useLazyGetControlObservationAttachmentsQuery,
  useGetOutstandingWorkprogramCountQuery,
  useGetOutstandingWorkprogramsQuery,
} = bookObservationApi;
