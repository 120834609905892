import { createApi } from '@reduxjs/toolkit/query/react';
import customFetchBase from 'src/store/customFetchBase';
import {
  AuditObservationResponse,
  EngagementResponse,
  ObservationSampleResponse
} from '../types';
import { NewFeedbackInput } from 'src/views/pages/compliance/Feedback';

export const complianceApi = createApi({
  reducerPath: 'complianceApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    sendObservationFeedback: builder.mutation<string, NewFeedbackInput>({
      query(data) {
        return {
          url: '/auditCompliance/complianceStatus',
          method: 'POST',
          body: data
        };
      }
    }),
    getComplianceObservations: builder.query<
      AuditObservationResponse[],
      string
    >({
      query(id) {
        return {
          url: `/auditCompliance/getObservations/${id}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditObservationResponseDTO: AuditObservationResponse[];
      }) => data.auditObservationResponseDTO
    }),
    getComplianceEngagements: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/auditCompliance/getEngagementsByStakeHolder',
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        auditEngagementResponseDTO: EngagementResponse[];
      }) => data.auditEngagementResponseDTO
    }),
    getObservationComplianceSampling: builder.query<
      ObservationSampleResponse,
      { engid: string; obvid: string }
    >({
      query({ engid, obvid }) {
        return {
          url: `/auditCompliance/getSampling/${engid}/${obvid}`,
          credentials: 'include'
        };
      },
      transformResponse: (data: {
        observationSampleResponseDTO: ObservationSampleResponse;
      }) => data.observationSampleResponseDTO
    })
  })
});

export const {
  useSendObservationFeedbackMutation,
  useGetComplianceEngagementsQuery,
  useLazyGetComplianceObservationsQuery,
  useLazyGetObservationComplianceSamplingQuery
} = complianceApi;
