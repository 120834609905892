import { Skeleton, Stack } from '@mui/material';

const AppSkeleton = () => {
  return (
    <>
      <Stack spacing={2} sx={{ px: 4 }}>
        <br />
        <Skeleton variant="rectangular" animation="wave" width="100%" />
        <br />
        <Skeleton variant="rectangular" animation="wave" width="100%" />
        <br />
        {/* <Skeleton variant="rectangular" animation="wave" /> */}
        {/* <Skeleton variant="rectangular" animation="wave" />
        <Skeleton variant="rectangular" animation="wave" /> */}
      </Stack>
    </>
  );
};

export default AppSkeleton;
