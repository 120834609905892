import moment from 'moment';

export const NewFileName = (file: any, filePart: string): string => {
  const fileNameParts = file.name.split('.');
  const fileExtension = fileNameParts.pop();
  const fileName = fileNameParts.pop();
  const newFileName = `${fileName}_${filePart}_${moment().format(
    'YYYYMMDDHHmmSSS.SSS'
  )}.${fileExtension}`;

  return newFileName;
};

export function toPascalCaseWithSpaces(word: string) {
  return word
    .replace(/([a-z])([A-Z])/g, '$1 $2')
    .replace(/\w+/g, function (match) {
      return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
    });
}

export function toPascalCase(word: string) {
  return word.replace(/\w+/g, function (match) {
    return match.charAt(0).toUpperCase() + match.slice(1).toLowerCase();
  });
}

export const formattedDate = (value: any) => {
  try {
    if (value) {
      return moment(value).format('DD-MM-YYYY');
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
};

export const formattedDateTime = (value: any) => {
  try {
    if (value) {
      return moment(value).format('DD MM, yyyy - h:mm:ss');
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
};

export const formattedDateYearFirst = (value: any) => {
  try {
    if (value) {
      return moment(value).format('YYYY-MM-DD');
    } else {
      return '';
    }
  } catch (error) {
    return '';
  }
};

export const getRandomColor = (): string => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  
  return color;
};

export const getLightRandomColor = (): string => {
  const getLightComponent = () => Math.floor(Math.random() * 155) + 100; // Adjust the range for lighter colors
  const r = getLightComponent();
  const g = getLightComponent();
  const b = getLightComponent();
  
  return `rgb(${r},${g},${b})`;
};