import * as React from 'react';
import { TextField, FormControlLabel, Paper, MenuItem } from '@mui/material';
import { getIn } from 'formik';
import { DatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import CustomTextField from 'src/components/forms/theme-elements/CustomTextField';
import CustomSwitch from 'src/components/forms/theme-elements/CustomSwitch';
import CustomCheckbox from 'src/components/forms/theme-elements/CustomCheckbox';
import CustomAutocomplete from 'src/components/forms/theme-elements/CustomAutoComplete';

interface Props {
  formik: any;
  label: string;
  name: string;
  placeholder: string;
  selectId?: string;
  displayName?: string;
  datasource?: any[];
  children?: any;
  onSelect?: (e: any, v: any) => void;
  value?: string;
  onFileChanged?: any;
  onChange?: (e: any, v: any) => void;
  disabled?: boolean;
  multiline?: boolean;
  rows?: number;
  type?: string;
  min?: number;
  max?: number;
  inputFormat?: string;
  checked?: boolean;
  defaultValue?: string;
}

export const MyTextField = ({ formik, label, name, placeholder, ...props }: Props) => {
  return (
    <>
      <CustomTextField
        error={
          Boolean(formik.touched[name] && formik.errors[name]) ||
          Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))
        }
        fullWidth
        name={name}
        label={label || placeholder}
        variant="outlined"
        {...formik.getFieldProps(name)}
        {...props}
        color="primary"
        size="medium"
      />
    </>
  );
};

export const MySelectField = ({ formik, label, name, datasource, ...props }: Props) => {
  return (
    <>
      <CustomTextField
        error={
          Boolean(formik.touched[name] && formik.errors[name]) ||
          Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))
        }
        fullWidth
        select
        label={label}
        name={name}
        {...formik.getFieldProps(name)}
        {...props}
        color="primary"
        variant="outlined"
        size="medium"
        defaultValue=""
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {datasource?.map((option, index) => (
          <MenuItem key={index.toString()} value={String(option.id)}>
            {option.title ? option.title : option[props.displayName || '']}
          </MenuItem>
        ))}
      </CustomTextField>
      {/* <Autocomplete
        disablePortal
        options={(datasource || [])?.map((option) => ({
          id: option.id,
          key: option[props.type] || props.type,
          label: option.title ? option.title : option[props.displayname]
        }))}
        onChange={(e, value) => formik.setFieldValue(name, value)}
        isOptionEqualToValue={(option, value) => option['id'] === value['id']}
        renderInput={(params) => (
          <TextField {...params} fullWidth label={label} variant="outlined" />
        )} 
      />*/}
      {/* Show an error message if there's any */}
      {/* {meta.error && (
        <label className="label">
          <span className="label-text-alt text-error">{meta.error}</span>
        </label>
      )} */}
    </>
  );
};

export const MyFixSelectField = ({ formik, label, name, children, ...props }: Props) => {
  return (
    <>
      <CustomTextField
        error={Boolean(formik.touched[name] && formik.errors[name])}
        fullWidth
        id="outlined-select-role-native"
        select
        label={label}
        name={name}
        // SelectProps={{
        //   native: true,
        // }}
        {...formik.getFieldProps(name)}
        {...props}
        color="primary"
        variant="outlined"
        size="medium"
      >
        {children}
      </CustomTextField>
    </>
  );
};

export const MyStatusSelectField = ({ formik, label, name, ...props }: Props) => {
  return (
    <>
      <CustomTextField
        error={Boolean(formik.touched[name] && formik.errors[name])}
        fullWidth
        id="outlined-select-role-native"
        select
        label={label}
        name={name}
        // SelectProps={{
        //   native: true,
        // }}
        {...formik.getFieldProps(name)}
        {...props}
        color="primary"
        variant="outlined"
        size="medium"
      >
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        <MenuItem key="1" value="true">
          Active
        </MenuItem>
        <MenuItem key="2" value="false">
          InActive
        </MenuItem>
      </CustomTextField>
    </>
  );
};

export const MyMultiSelectField = ({
  formik,
  label,
  name,
  placeholder,
  datasource,
  onSelect,
  ...props
}: Props) => {
  return (
    <>
      <CustomAutocomplete
        multiple
        fullWidth
        freeSolo
        sx={{
          m: 0,
        }}
        limitTags={15}
        options={datasource || []}
        getOptionLabel={(option: any) =>
          option.title ? option.title : option[props.displayName || '']
        }
        {...formik.getFieldProps(name)}
        onChange={(e: any, value: any) => {
          formik.setFieldValue(name, value);

          if (onSelect) {
            onSelect(e, value);
          }
        }}
        {...props}
        isOptionEqualToValue={(option: { [x: string]: any }, value: { [x: string]: any }) =>
          option['id'] === value['id']
        }
        renderInput={(params: JSX.IntrinsicAttributes) => (
          <TextField
            {...params}
            error={
              Boolean(formik.touched[name] && formik.errors[name]) ||
              Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))
            }
            fullWidth
            label={label}
            variant="outlined"
            placeholder={placeholder}
          />
        )}
        color="primary"
      />
    </>
  );
};

export const MySearcheableSelectField = ({
  formik,
  label,
  name,
  datasource,
  onSelect,
  value,
  ...props
}: Props) => {
  return (
    <>
      <CustomAutocomplete
        freeSolo
        fullWidth
        options={datasource || []}
        getOptionLabel={(option: any) =>
          option.title ? option.title : option[props.displayName || '']
        }
        onChange={(e: any, value: any) => {
          if (value) {
            formik.setFieldValue(name, value);
          }

          if (onSelect) {
            onSelect(e, value);
          }

          formik.handleChange(e);
        }}
        value={value}
        {...props}
        renderInput={(params: JSX.IntrinsicAttributes) => (
          <TextField
            {...params}
            error={
              Boolean(formik.touched[name] && formik.errors[name]) ||
              Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))
            }
            fullWidth
            label={label}
            variant="outlined"
            placeholder=""
            size="medium"
          />
        )}
      />
    </>
  );
};

export const MySwitch = ({ formik, name, label, ...props }: Props) => {
  return (
    <>
      <FormControlLabel
        control={
          <CustomSwitch
            name={name}
            defaultChecked={false}
            onChange={formik.handleChange}
            // checked={formik.values[name] || false}
            {...props}
          />
        }
        label={label}
        color="primary"
      />
    </>
  );
};

export const MyCheckbox = ({ formik, name, label }: Props) => {
  return (
    <>
      <FormControlLabel
        control={
          <CustomCheckbox
            name={name}
            defaultChecked={false}
            onChange={formik.handleChange}
            checked={formik.values[name]}
          />
        }
        label={label}
        color="primary"
      />
    </>
  );
};

export const MyUpload = ({ formik, label, name, placeholder, onFileChanged, ...props }: Props) => {
  return (
    <>
      <CustomTextField
        fullWidth
        onChange={(e: { target: { files: any } }) => {
          onFileChanged(e.target.files);

          formik.handleChange(e);
        }}
        formik={formik}
        label=""
        variant="outlined"
        name={name}
        placeholder={placeholder}
        type="file"
        {...props}
        color="primary"
        InputProps={{
          inputProps: { accept: '.jpg,.jpeg,.png,.gif,.pdf' },
          // onChange: onFileChanged,
          text: { placeholder },
          // endAdornment: (
          //   <Button
          //     variant="contained"
          //     component="span"
          //     startIcon={<CloudUpload />}
          //   >
          //     Custom Button Text
          //   </Button>
          // )
        }}
        size="medium"
      />
    </>
  );
};

// Add a name property and reuse the date picker props.
// type Props<TDate> = {
//   formik: any;
//   name: string;
// } & DatePickerProps<TDate>;

export const MyDatePicker = ({ formik, name, ...props }: Props) => {
  // use useField hook to get the state values for this field via the name prop.
  // const [field, meta] = formik.useField(name);
  //const { setFieldValue } = useFormikContext();
  return (
    <>
      <DatePicker
        {...props}
        // use the DatePicker component override the value formik state value
        value={formik.values[name]}
        // modify the formik sate using setFieldValue
        onChange={(val) => {
          formik.setFieldValue(name, val);
        }}
        renderInput={(props) => (
          <CustomTextField
            {...props}
            name={name}
            error={
              Boolean(formik.touched[name] && formik.errors[name]) ||
              Boolean(getIn(formik.touched, name) && getIn(formik.errors, name))
            }
            fullWidth
            variant="outlined"
            size="medium"
          />
        )}
      />
      {/* Show an error message if there's any */}
      {/* {meta.error && (
        <label className="label">
          <span className="label-text-alt text-error">{meta.error}</span>
        </label>
      )} */}
    </>
  );
};

interface ChipData {
  id: bigint;
  title: string;
}

const ListItem = styled('li')(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export const ChipsList = ({
  chipData,
  propagateHandleChange,
}: {
  chipData: any;
  propagateHandleChange: any;
}) => {
  const [chipDataState, setChipDataState] = React.useState<ChipData[]>(chipData);

  const handleDelete = (chipToDelete: ChipData) => () => {
    setChipDataState((chips) => chips.filter((chip) => chip.id !== chipToDelete.id));
  };

  React.useEffect(() => {
    propagateHandleChange(chipDataState);
  }, [chipDataState]);

  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {chipDataState.map((data) => {
        // let icon;

        // if (data.label === 'React') {
        //   icon = <TagFacesIcon />;
        // }

        return (
          <ListItem key={data.id.toString()}>
            <Chip
              // icon={icon}
              label={data.title}
              // onDelete={data.label === 'React' ? undefined : handleDelete(data)}
              onDelete={handleDelete(data)}
            />
          </ListItem>
        );
      })}
    </Paper>
  );
};

// InputProps={{
//   inputProps: { min: 0, max: 100 },
//   onChange: handleInputChange,
//   type: 'number',
// }}
