import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import customFetchBase from 'src/store/customFetchBase';
import {
  ApiParams,
  AuditObservationResponse,
  EngagementResponse,
  ObservationStatusSummaryView,
  ObservationSummaryView,
  PostCompObservationSummaryView,
} from 'src/store/types';

export const dashboardApi = createApi({
  reducerPath: 'dashboardApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getDashboardKPIs: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getAuditorData`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwAuditorDashBoard: any[] }) => data.vwAuditorDashBoard,
    }),
    getClientDashboardKPIs: builder.query<any[], void>({
      query() {
        return {
          url: `/aduiteeDashBoard/getAuditeeData`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwAuditeeDashBoard: any[] }) => data.vwAuditeeDashBoard,
    }),
    getObservationAgingKPI: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getOutstandingObvAging`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwOutstandingOBVAging: any[] }) => data.vwOutstandingOBVAging,
    }),
    getDepartmentDashboardKPIs: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getResponsibleDptWise`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwAuditorDashBoardResponsibleDptWise: any[] }) =>
        data.vwAuditorDashBoardResponsibleDptWise,
    }),
    getPostCompObservationSummaryView: builder.query<PostCompObservationSummaryView[], ApiParams>({
      query({ page, size }) {
        return {
          url: `/listing/getPostCompObvSummary/${page}/${size}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: {
        vwPostCompObservationSummary: PostCompObservationSummaryView[];
      }) => data.vwPostCompObservationSummary,
    }),
    getObservationSummaryView: builder.query<ObservationSummaryView[], ApiParams>({
      query({ page, size }) {
        return {
          url: `/listing/getObvSummary/${page}/${size}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationSummary: ObservationSummaryView[] }) =>
        data.vwObservationSummary,
    }),
    getDashboardObservationSummaryView: builder.query<ObservationSummaryView[], ApiParams>({
      query({ statid, page, size }) {
        return {
          url: `/dashBoard/getObvSummary/${statid}/${page}/${size}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationSummary: ObservationSummaryView[] }) =>
        data.vwObservationSummary,
    }),
    getObservationStatusSummaryView: builder.query<ObservationStatusSummaryView[], ApiParams>({
      query({ page, size }) {
        return {
          url: `/listing/getObvStatusSummary/${page}/${size}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationSummaryStatus: ObservationStatusSummaryView[] }) =>
        data.vwObservationSummaryStatus,
    }),
    getDashboardObservationStatusSummaryView: builder.query<
      ObservationStatusSummaryView[],
      ApiParams
    >({
      query({ page, size }) {
        return {
          url: `/dashBoard/getObvStatusSummary/${page}/${size}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationSummaryStatus: ObservationStatusSummaryView[] }) =>
        data.vwObservationSummaryStatus,
    }),
    getBranchAuditorPerformanceView: builder.query<any[], number>({
      query(engid) {
        return {
          url: `/listing/branchAuditorPerformance/${engid}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwBranchAuditorPerformance: any[] }) =>
        data.vwBranchAuditorPerformance,
    }),
    getRiskMatrixView: builder.query<any[], void>({
      query() {
        return {
          url: `/listing/getRiskMatrix`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { riskMatrixResponseDTO: any[] }) => data.riskMatrixResponseDTO,
    }),
    getAuditPeriodSummary: builder.query<any[], string>({
      query(type) {
        return {
          url: `/listing/getPrdSmry/${type}/1/999`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwAuditPeriodSummary: any[] }) => data.vwAuditPeriodSummary,
    }),
    getEngagementSummaryHeaderView: builder.query<any, void>({
      query() {
        return {
          url: `/engPlanExec/getEngExecPlan`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { engPlanExecResponseDTO: any[] }) => data.engPlanExecResponseDTO,
    }),
    getEngagementAuditProgressView: builder.query<any, string>({
      query(id) {
        return {
          url: `/auditRating/getAuditRating/${id}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditRatingResponseDTO: any[] }) => data.auditRatingResponseDTO,
    }),
    getEngagementSummaryHeaderDashboardView: builder.query<any, void>({
      query() {
        return {
          url: `/dashBoard/getEngExecPlan`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { engPlanExecResponseDTO: any[] }) => data.engPlanExecResponseDTO,
    }),
    getEngagementSummaryDetailView: builder.query<any[], number>({
      query(status) {
        return {
          url: `/engPlanExec/getEngPlanByStatus/${status}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: any[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getObservationComplianceStatsView: builder.query<any, void>({
      query() {
        return {
          url: `/compSummary/getObvCompSummaryStats`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { obvCompSummaryStats: any }) => data.obvCompSummaryStats,
    }),
    getComplianceObservationsView: builder.query<AuditObservationResponse[], number>({
      query(status) {
        return {
          url: `/compSummary/getObvByCompStatus/${status}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditObservationResponseDTO: AuditObservationResponse[] }) =>
        data.auditObservationResponseDTO,
    }),
    getArchiveEngagements: builder.query<EngagementResponse[], void>({
      query() {
        return {
          url: '/archiveEng/getFinalEngagementsByUser',
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getArchiveEngagementsByStatus: builder.query<EngagementResponse[], ApiParams>({
      query({ status, attachmentType }) {
        return {
          url: `/archiveEng/getFinalEngagementsByStakeHolder/${status}/${attachmentType}`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { auditEngagementResponseDTO: EngagementResponse[] }) =>
        data.auditEngagementResponseDTO,
    }),
    getAuditDaysPlanning: builder.query<any[], void>({
      query() {
        return {
          url: `/listing/getplan/1/999`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwAuditDaysPlanning: any[] }) => data.vwAuditDaysPlanning,
    }),
    getZBCompliancePercentage: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getcompliancepercentage`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwCompliancePercentage: any[] }) => data.vwCompliancePercentage,
    }),
    getZBBranchReportRating: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getallreportrating`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwAllReportRating: any[] }) => data.vwAllReportRating,
    }),
    getZBComplianceByRegion: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getcompliancebyregion`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwComplianceByRegion: any[] }) => data.vwComplianceByRegion,
    }),
    getZBObvOpenRisk: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getobvopenrisk`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationOpenRisk: any[] }) => data.vwObservationOpenRisk,
    }),
    getZBObvOpenRiskByRegion: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getobvopenriskbyregion`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationOpenRiskByRegion: any[] }) =>
        data.vwObservationOpenRiskByRegion,
    }),
    getZBLapsTargets: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getlapstargets`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwLapsTargets: any[] }) => data.vwLapsTargets,
    }),
    getZBLapsTargetsByRegion: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getlapstargetsregion`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwLapsTargetsRegion: any[] }) => data.vwLapsTargetsRegion,
    }),
    getZBObvOpenAging: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getobvopenaging`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationOpenAging: any[] }) => data.vwObservationOpenAging,
    }),
    getZBObvOpenAgingByRegion: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getobvopenagingbyregion`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationOpenAgingByRegion: any[] }) =>
        data.vwObservationOpenAgingByRegion,
    }),
    getZBObvCountStatus: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getobvcountstatus`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationCountStatus: any[] }) =>
        data.vwObservationCountStatus,
    }),
    getZBObvCountStatusByRegion: builder.query<any[], void>({
      query() {
        return {
          url: `/dashBoard/getobvcountstatusbyregion`,
          credentials: 'include',
        };
      },
      transformResponse: (data: { vwObservationCountStatusByRegion: any[] }) =>
        data.vwObservationCountStatusByRegion,
    }),
  }),
});

export const {
  useGetDashboardKPIsQuery,
  useGetClientDashboardKPIsQuery,
  useGetObservationAgingKPIQuery,
  useGetDepartmentDashboardKPIsQuery,
  useLazyGetObservationSummaryViewQuery,
  useLazyGetDashboardObservationSummaryViewQuery,
  useLazyGetPostCompObservationSummaryViewQuery,
  useLazyGetObservationStatusSummaryViewQuery,
  useLazyGetDashboardObservationStatusSummaryViewQuery,
  useLazyGetBranchAuditorPerformanceViewQuery,
  useGetRiskMatrixViewQuery,
  useGetEngagementSummaryHeaderViewQuery,
  useLazyGetEngagementAuditProgressViewQuery,
  useGetEngagementSummaryHeaderDashboardViewQuery,
  useLazyGetEngagementSummaryDetailViewQuery,
  useGetObservationComplianceStatsViewQuery,
  useLazyGetComplianceObservationsViewQuery,
  useGetArchiveEngagementsQuery,
  useLazyGetArchiveEngagementsByStatusQuery,
  useLazyGetAuditPeriodSummaryQuery,
  useGetAuditDaysPlanningQuery,
  useGetZBCompliancePercentageQuery,
  useGetZBObvOpenRiskQuery,
  useGetZBLapsTargetsQuery,
  useGetZBObvOpenAgingQuery,
  useGetZBObvCountStatusQuery,
  useGetZBObvCountStatusByRegionQuery,
  useGetZBComplianceByRegionQuery,
  useGetZBObvOpenRiskByRegionQuery,
  useGetZBObvOpenAgingByRegionQuery,
  useGetZBLapsTargetsByRegionQuery,
  useGetZBBranchReportRatingQuery
} = dashboardApi;
